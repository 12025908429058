import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["branch", "stations"];

  connect() {
    let branch = $(this.branchTarget);
    let stations = $(this.stationsTarget);

    // Impedir configuração duplicada do Select2
    if (stations.data("select2")) {
      return; // Já inicializado, evitar nova configuração
    }

    // Configurar evento de "select2:select" apenas se necessário
    branch.on("select2:select", () => {
      const event = new Event("change", { bubbles: true });
      this.branchTarget.dispatchEvent(event);
    });

    // Configuração do Select2
    stations.select2({
      language: "pt-BR",
      placeholder: "Selecionar...",
      ajax: {
        url: `/stations_list/`,
        dataType: "json",
        delay: 250,
        data: (params) => ({
          q: params.term,
          branch_id: branch.val(),
        }),
        processResults: (data) => ({
          results: data.map((item) => ({ id: item.id, text: item.name })),
        }),
        cache: true,
      },
      minimumInputLength: 1,
    });

    stations.on("select2:open", (e) => {
      setTimeout(function () {
        document
          .querySelector(".select2-container--open .select2-search__field")
          .focus();
      }, 0);
    });
  }

  disconnect() {
    let stations = $(this.stationsTarget);

    // Destruir o Select2 apenas se ele estiver inicializado
    if (stations.data("select2")) {
      stations.select2("destroy");
    }
  }
}

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["openButton", "modal", "background", "closeButton"];

  connect() {
    if (this.hasOpenButtonTarget && this.hasCloseButtonTarget && this.hasBackgroundTarget) {
      this.openButtonTarget.addEventListener("click", () => this.openModal());
      this.closeButtonTarget.addEventListener("click", () => this.closeModal());
      this.backgroundTarget.addEventListener("click", () => this.closeModal());
    }
  }

  openModal() {
    this.modalTarget.classList.add("is-active");
    document.querySelector("html").classList.add("is-clipped");
  }
  closeModal() {
    this.modalTarget.classList.remove("is-active");
    document.querySelector("html").classList.remove("is-clipped");
  }
}

import { Controller } from "stimulus";
import "mmenu-js";

export default class extends Controller {
  static targets = ["page", "menu", "showButton", "hideButton"];

  connect() {
    new Mmenu(
      "#menu",
      {
        navbar: {
          title: "Core",
        },
        scrollBugFix: {
          fix: true,
        },
        counters: {
          add: true,
        },
        theme: "dark",
        iconPanels: {
          add: false,
          visible: 1,
        },
        navbars: [
          {
            position: "top",
            content: ["searchfield"],
          },
        ],
        setSelected: {
          hover: true,
        },
      },
      {
        language: "pt_br",
        scrollBugFix: {
          fix: true,
        },
        offCanvas: {
          page: {
            nodetype: "main",
            selector: "#page",
          },
        },
      }
    );

    document.addEventListener("turbolinks:load", () => {
      let menuCloseLink = document.getElementById("mm-1");

      if (menuCloseLink) {
        menuCloseLink.remove();
        this.pageTarget.inert = false;
      }

      if (this.pageTarget) {
        this.pageTarget.classList.remove('mm-page', 'mm-slideout');
      }
    });

    document.addEventListener("keydown", (event) => {
      if (event.altKey) this.showMenu();
      else if (event.key == "Escape") this.hideMenu();
    });

    window.addEventListener("resize", () => {
      if (window.innerWidth < 769) {
        this.hideMenu();
      } else {
        this.showMenu();
      }
    });

    if (window.innerWidth > 769) {
      this.showMenu();
    } else {
      this.hideMenu();
    }
  }

  showMenu() {
    this.showButtonTarget.classList.add("is-hidden");
    this.showButtonTarget.click();
    this.hideButtonTarget.classList.remove("is-hidden");
    document
      .getElementsByClassName("mm-searchfield")[0]
      .firstElementChild.firstElementChild.focus({ focusVisible: true });
    let container = document.getElementsByTagName("main");
    this.pageTarget.inert = false;

    this.menuTarget.style.width = "300px";
    container[0].style.paddingLeft = "300px";
  }

  hideMenu() {
    this.hideButtonTarget.classList.add("is-hidden");
    this.showButtonTarget.classList.remove("is-hidden");
    this.hideButtonTarget.click();
    let container = document.getElementsByTagName("main");
    this.pageTarget.inert = false;

    this.menuTarget.style.width = "35px";
    container[0].style.paddingLeft = "35px";
  }
}
